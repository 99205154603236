<template>
  <div class="card">
    <div class="card-header">
      <h3>{{ $t(title) }}</h3>
    </div>
    <div class="card-body">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="6" sm="12">
              <b-row>
                <!-- Name -->
                <b-col cols="12">
                  <b-form-group :label="$t('Location name')" label-for="name">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Location name')"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        v-model="formData.name"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('Location name')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Details -->
                <b-col cols="12">
                  <b-form-group :label="$t('Address')" label-for="details">
                    <gmap-autocomplete
                      ref="locationautocomplete"
                      :value="formData.details"
                      class="form-control"
                      @place_changed="setPlace"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6" sm="12">
              <b-row>
                <b-col cols="12">
                  <google-map
                    ref="googleMap"
                    :zoom="12"
                    :show-search="true"
                    :location="location"
                    styling="width: 100%; height: 400px"
                    @input="getPosition"
                  />
                </b-col>
              </b-row>
            </b-col>
            <!-- Save Location button -->
            <b-col cols="12" class="mt-2">
              <b-button
                variant="primary"
                type="submit"
                class="d-flex"
                :disabled="submitLoader || !formChanged"
                @click.prevent="validationForm"
              >
                <b-spinner v-if="submitLoader" small class="mr-05" />
                <span>
                  {{
                    formData.id === -1
                      ? submitLoader
                        ? $t('Loading...')
                        : $t('Save location')
                      : submitLoader
                      ? $t('Loading...')
                      : $t('Update location')
                  }}
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BSpinner } from 'bootstrap-vue'
import { required } from '@/libs/validations'
import LocationService from '@/services/LocationService'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BSpinner,
  },
  props: {
    title: {
      type: String,
      default: 'Create a new location',
    },
    formData: {
      type: Object,
      default: () => {
        return {
          id: -1,
          name: '',
          details: '',
          city: undefined,
          google_place_id: undefined,
          metadata: undefined,
          longitude: -3.713,
          latitude: 40.2085,
        }
      },
    },
  },
  data() {
    return {
      LocationService: new LocationService(),
      submitLoader: false,
      neighborhood: null,
      postal_code: null,
      formChanged: false,
      required,
    }
  },
  computed: {
    location() {
      return {
        lng: +this.formData.longitude,
        lat: +this.formData.latitude,
      }
    },
  },
  mounted() {
    if (!this.formData.name) {
      this.formData.name = this.$store.state.auth.user.business_name
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submit()
        }
      })
    },
    getPosition(position) {
      this.formData.longitude = position.lng
      this.formData.latitude = position.lat
    },
    submit() {
      this.submitLoader = true
      if (this.formData.id === -1) this.create()
      else this.update()
    },
    create() {
      this.formData.metadata = JSON.stringify({
        neighborhood: this.neighborhood,
        postal_code: this.postal_code,
      })
      this.LocationService.create(this.formData)
        .then(success => {
          this.submitLoader = false
          this.$router.push({ name: 'locations' })
        })
        .catch(err => {
          this.submitLoader = false
          console.warn(err)
        })
    },
    update() {
      this.formData.metadata = {
        neighborhood: this.neighborhood,
        postal_code: this.postal_code,
      }
      this.LocationService.update(this.formData.id, this.formData)
        .then(success => {
          this.submitLoader = false
          this.$router.push({ name: 'locations' })
        })
        .catch(err => {
          this.submitLoader = false
          console.warn(err)
        })
    },
    setPlace(place) {
      this.formChanged = true
      place.address_components.forEach(particle => {
        if (particle.types.includes('locality')) {
          this.formData.city = particle.long_name
        }
        if (particle.types.includes('sublocality')) {
          this.neighborhood = particle.long_name
        }
        if (particle.types.includes('postal_code')) {
          this.postal_code = particle.long_name
        }
      })

      this.formData.google_place_id = place.place_id

      if (!this.formData.city) {
        this.formData.city = 'any'
      }
      const marker = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.formData.details = place.formatted_address
      this.$refs.googleMap.position = marker
      this.getPosition(marker)
    },
  },
}
</script>
<style lang="scss" scoped>
.mr-05 {
  margin-right: 0.5rem;
}

.pac-target-input {
  width: 100%;
  margin-bottom: 15px;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
</style>
