<template>
  <div class="">
    <LocationForm/>
  </div>
</template>

<script>
import LocationForm from "@/views/pages/locations/Location-Form"

export default {
  name: "Create",
  components: {
    LocationForm
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {}
}
</script>

<style scoped>

</style>
